<template>
	<figure class="avatar" :class="`is-${size}`">
		<span v-if="hasDot" class="icon-dot has-background-red has-border-white"></span>
		<img alt="avatar" :src="avatar.url" />
	</figure>
</template>

<script>
export default {
	name: 'Avatar',
	props: {
		avatar: {
			type: Object,
			required: true,
		},
		size: {
			type: String,
			default: 'medium',
		},
		hasDot: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.avatar {
	position: relative;
	.icon-dot {
		position: absolute;
		top: -5%;
		right: -8%;
		border-radius: 50%;
		border-style: solid;
	}

	&.is-xsmall {
		width: 1.75rem;
		.icon-dot {
			border-width: 0.15rem;
			height: 0.75rem;
			width: 0.75rem;
		}
	}
	&.is-small {
		width: 2rem;
		.icon-dot {
			border-width: 0.15rem;
			height: 0.75rem;
			width: 0.75rem;
		}
	}
	&.is-medium {
		width: 2.7rem;
		.icon-dot {
			border-width: 0.2rem;
			height: 1rem;
			width: 1rem;
		}
	}
	&.is-large {
		width: 8rem;
		.icon-dot {
			border-width: 0.6rem;
			height: 3rem;
			width: 3rem;
		}
	}
}
</style>
