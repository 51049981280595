import http from './http';

export function getUser() {
	return http.get('/user').then(resp => resp.data.data);
}

export function getSchools() {
	return http.get('/user/schools').then(resp => resp.data.data);
}

export function updateProfile(payload) {
	return http.put('/user/profile', payload).then(resp => resp.data.data);
}

export function updatePassword(current_password, password, password_confirmation) {
	return http
		.put('/user/password', {
			current_password,
			password,
			password_confirmation,
		})
		.then(resp => resp.data.data);
}

export function getAvatars() {
	return http.get('/user/avatars').then(resp => resp.data);
}

export function getDashboard(schoolId) {
	return http.get(`/schools/${schoolId}/user/dashboard`).then(resp => resp.data.data);
}

export function ping() {
	return http.post('/user/ping');
}
