<template>
	<div
		v-tippy="{ placement: 'bottom', content: tooltipText }"
		class="avatar-stack is-flex is-align-items-center"
		:class="`is-${size}`"
	>
		<Avatar v-for="user in shownUsers" :key="user.id" class="avatar" :avatar="user.avatar" :size="size" />
		<div v-if="collapsed" class="remaining-count is-flex is-align-items-center is-justify-content-center">
			+{{ remainingUsers.length }}
		</div>
	</div>
</template>

<script>
import Avatar from '@/components/ui/user/Avatar';

export default {
	name: 'AvatarStack',
	components: {
		Avatar,
	},
	props: {
		users: {
			type: Array,
			required: true,
		},
		size: {
			type: String,
			default: 'medium',
		},
	},
	computed: {
		shownUsers() {
			return this.collapsed ? this.users.slice(0, 3) : this.users;
		},
		collapsed() {
			return this.users.length > 4;
		},
		remainingUsers() {
			return this.users.slice(3);
		},
		tooltipText() {
			if (this.users.length === 1) {
				return `${this.users.length} elev på holdet`;
			}
			return `${this.users.length} elever på holdet`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.avatar-stack {
	.avatar {
		background-color: #fff;
		border-radius: 50%;
	}

	.remaining-count {
		background-color: $grey-lighter;
		border-radius: 50%;
		text-align: center;
		color: $grey-dark;
		font-weight: bold;
		border: 2px solid #fff;
	}
	&.is-small {
		.avatar {
			margin-right: -0.6rem;
		}
		.remaining-count {
			width: 2rem;
			height: 2rem;
			font-size: 0.8rem;
		}
	}
	&.is-medium {
		.avatar {
			margin-right: -0.75rem;
		}
		.remaining-count {
			margin-top: 4px;
			width: 2.7rem;
			height: 2.7rem;
			font-size: 0.9rem;
		}
	}
}
</style>
