<template>
	<component
		:is="type"
		:to="path"
		:href="href"
		:target="type == 'a' ? '_blank' : null"
		class="tile super-user-tile is-child box has-text is-clickable"
		:class="classes"
	>
		<div class="is-flex is-align-items-center">
			<p v-if="!completed" class="title m-0 tile-number is-size-4 is-flex-shrink-0">
				{{ number }}
			</p>
			<BaseIcon v-else icon="check" color="green" :size="5" :active="true" :show-background="true" />
			<p class="title is-4 ml-3">{{ title }}</p>
		</div>
		<div class="content">
			<slot />
		</div>
	</component>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';

export default {
	name: 'TeacherSuperUserTile',
	components: {
		BaseIcon,
	},
	props: {
		number: {
			type: Number,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		completed: {
			type: Boolean,
			default: false,
		},
		highlighted: {
			type: Boolean,
			default: false,
		},
		path: {
			type: Object,
			required: false,
			default: null,
		},
		type: {
			type: String,
			required: true,
		},
		href: {
			type: String,
			required: false,
			default: null,
		},
	},
	computed: {
		classes() {
			if (this.completed) {
				return ['has-background-green', 'is-shadowless', 'completed'];
			}
			if (this.highlighted) {
				return ['has-background-white', 'highlighted'];
			}
			return ['has-border', 'is-shadowless'];
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.super-user-tile {
	padding: 1rem;
	background-color: transparent;

	&.has-border {
		border: 4px solid $grey-lighter;
	}

	.tile-number {
		width: 51px;
		height: 51px;
		border: 4px solid $grey-lighter;
		border-radius: 50%;
		display: flex; /* or inline-flex */
		align-items: center;
		justify-content: center;
		transition: all 250ms ease;
	}

	.title {
		color: $grey;
		transition: color 250ms ease;
	}

	&:hover,
	&.highlighted {
		.tile-number {
			background-color: $blue;
			color: $white !important;
			border: none;
		}

		.title {
			color: $blue;
		}
	}

	&.completed {
		.title {
			color: $white;
		}
	}
}
</style>
