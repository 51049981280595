<template>
	<TeacherSuperUserTile
		:type="type"
		class="wrapper is-relative"
		:number="number"
		:title="title"
		:completed="completed"
		:highlighted="highlighted"
	>
		<BaseIcon
			class="play-icon"
			style="padding-left: 4px"
			icon="play"
			:color="completed ? 'green' : 'blue'"
			:size="9"
			:show-background="true"
		/>
	</TeacherSuperUserTile>
</template>

<script>
import TeacherSuperUserTile from './TeacherSuperUserTile';
import BaseIcon from '@/components/base/BaseIcon';

export default {
	name: 'TeacherSuperUserVideoTile',
	components: {
		TeacherSuperUserTile,
		BaseIcon,
	},
	props: {
		number: {
			type: Number,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		completed: {
			type: Boolean,
			default: false,
		},
		highlighted: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

.wrapper {
	background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
		url('/public/images/explorer/video-preview.png');
	background-size: cover;
	background-position: center;

	&.completed {
		background: linear-gradient(rgba($green, 1), rgba($green, 1)),
			url('/public/images/explorer/video-preview.png');
		background-blend-mode: multiply;
		background-size: cover;
		background-position: center;
	}

	:deep(.title) {
		color: $white !important;
	}

	:deep(.content) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
