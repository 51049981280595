<template>
	<div>
		<div class="is-flex is-align-items-center mb-5">
			<BaseIcon icon="cateye" color="purple" no-padding />
			<p class="title is-5 ml-3">Udforsk</p>
		</div>

		<div class="tile is-ancestor">
			<div class="tile is-parent is-8">
				<router-link
					:to="{
						name: 'homework',
					}"
					class="tile is-child box has-background-purple"
				>
					<div class="columns is-vcentered">
						<div class="column">
							<p class="title is-4 has-text-white">Opret lektie</p>
							<p class="has-text-white">
								Vælg tekst, øvelser og videoer til dine elever.
							</p>
						</div>
						<div class="column">
							<img src="/images/explorer/homework-card.svg" />
						</div>
					</div>
				</router-link>
			</div>
			<div class="tile is-parent">
				<div class="tile is-child box news-tile">
					<div
						class="news-tile-header has-background-white section is-flex is-align-items-center"
					>
						<BaseIcon icon="flag" color="green" :size="5" show-background />
						<p class="title is-6 ml-3">Nyheder</p>
					</div>
					<div class="section news-tile-list has-background-grey-lighterer">
						<a
							v-for="(story, key) in newslist"
							:key="key"
							:href="story.url"
							target="_blank"
						>
							<span class="title is-8 has-text-grey has-text-weight-normal">
								{{ story.date | dateFormat('D. MMMM YYYY') }}
							</span>
							<p class="title news-title is-7 mb-5">
								{{ story.title }}
							</p>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="tile is-ancestor">
			<div class="tile is-parent is-stacked is-8">
				<div class="tile">
					<div class="tile">
						<div class="tile">
							<div class="tile is-child box">
								<div class="is-flex is-align-items-center mb-5">
									<BaseIcon
										icon="star"
										color="yellow"
										:size="5"
										show-background
									/>
									<p class="title is-6 ml-3">Tip</p>
								</div>
								<BaseSlider :items="tips" :height="260" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tile is-parent">
				<div class="tile is-child box">
					<div class="is-flex is-align-items-center mb-3">
						<BaseIcon icon="scroll" color="blue" :size="5" show-background />

						<p class="title is-6 ml-3">Superbruger</p>
					</div>
					<p v-text="superUserTeaser"></p>
					<div
						class="is-flex is-align-items-center mt-4 is-clickable"
						@click="completeIntroVideoStep"
					>
						<BaseCheckbox
							:active-color="
								progress.has_clicked_intro_video == true
									? 'green'
									: 'grey-light'
							"
							rounded
							selected
						/>
						<p
							class="title is-7 ml-3 has-text-grey"
							:class="{
								'is-strikethrough':
									progress.has_clicked_intro_video == true,
							}"
						>
							Velkomstvideo
						</p>
					</div>
					<router-link
						class="is-flex is-align-items-center mt-4"
						:to="{
							name: 'school_classes',
						}"
					>
						<BaseCheckbox
							:active-color="
								progress.has_created_schoolclass == true
									? 'green'
									: 'grey-light'
							"
							rounded
							selected
						/>
						<p
							class="title is-7 ml-3 has-text-grey"
							:class="{
								'is-strikethrough':
									progress.has_created_schoolclass == true,
							}"
						>
							Opret hold
						</p>
					</router-link>
					<router-link
						class="is-flex is-align-items-center mt-4"
						:to="{
							name: 'homework',
						}"
					>
						<BaseCheckbox
							:active-color="
								progress.has_created_homework == true
									? 'green'
									: 'grey-light'
							"
							rounded
							selected
						/>
						<p
							class="title is-7 ml-3 has-text-grey"
							:class="{
								'is-strikethrough':
									progress.has_created_homework == true,
							}"
						>
							Opret lektie
						</p>
					</router-link>
					<a
						href="https://www.fejlretter.dk/inspiration-til-dit-rettearbejde"
						class="is-flex is-align-items-center mt-4 is-clickable"
						target="_blank"
						@click="completeEditoolStep"
					>
						<BaseCheckbox
							:active-color="
								progress.has_clicked_editool_link == true
									? 'green'
									: 'grey-light'
							"
							rounded
							selected
						/>
						<p
							class="title is-7 ml-3 has-text-grey"
							:class="{
								'is-strikethrough':
									progress.has_clicked_editool_link == true,
							}"
						>
							Tilføj Fejlretter
						</p>
					</a>
					<router-link
						class="is-flex is-align-items-center mt-4"
						:to="{
							name: 'profile',
						}"
					>
						<BaseCheckbox
							:active-color="
								progress.has_confirmed_information == true
									? 'green'
									: 'grey-light'
							"
							rounded
							selected
						/>
						<p
							class="title is-7 ml-3 has-text-grey"
							:class="{
								'is-strikethrough':
									progress.has_confirmed_information == true,
							}"
						>
							Bekræft oplysninger
						</p>
					</router-link>
				</div>
			</div>
		</div>

		<div class="tile is-ancestor">
			<div class="tile is-parent is-4">
				<a
					href="https://support.minlaering.dk/fejlretter/komgodtigang"
					target="_blank"
					class="tile is-child box"
				>
					<div class="is-flex is-align-items-center mb-3">
						<img src="/images/icons/editool.svg" width="50" />
						<p class="title is-6 ml-3">Fejlretter</p>
					</div>
					<p>Ret afleveringer med det digitale retteprogram.</p>
				</a>
			</div>
			<div class="tile is-parent is-4">
				<a href="https://support.minlaering.dk" target="_blank" class="tile is-child box">
					<div class="is-flex is-align-items-center mb-3">
						<BaseIcon icon="lifebuoy" color="red" :size="5" show-background />
						<p class="title is-6 ml-3">Hjælp</p>
					</div>
					<p>Find bl.a. lærerguides til alle læremidler.</p>
				</a>
			</div>
		</div>

		<div v-if="achievements.length > 0">
			<div class="is-flex is-align-items-center mb-5">
				<BaseIcon icon="achievements" color="yellow" no-padding />
				<p class="title is-5 ml-3">Bedrifter</p>
			</div>

			<div class="columns is-multiline is-variable">
				<div v-for="(achievement, index) in achievements" :key="index" class="column is-4">
					<AchievementCard
						:achievement="achievement"
						:user-achievement-levels="getUserAchievementLevels(achievement)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AchievementCard from '@/components/dashboard/AchievementCard';
import BaseIcon from '@/components/base/BaseIcon';
import BaseSlider from '@/components/base/BaseSlider';
import BaseCheckbox from '@/components/base/input/BaseCheckbox';
import date from '@/mixins/filters/date';

export default {
	name: 'ExploreTeacher',
	components: { AchievementCard, BaseIcon, BaseSlider, BaseCheckbox },
	mixins: [date],
	props: {
		achievements: {
			type: Array,
			default: () => [],
		},
		userAchievementLevels: {
			type: Array,
			default: () => [],
		},
		news: {
			type: Array,
			default: () => [],
		},
		tips: {
			type: Array,
			default: () => [],
		},
		progress: {
			type: Object,
			required: true,
		},
		isSuperUser: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		newslist() {
			return this.news.slice(0, 3);
		},
		superUserTeaser() {
			if (this.isSuperUser) {
				return 'Godt gået! Du har gennemført alle trin.';
			}

			return 'Gennemfør følgende trin for at blive superbruger!';
		},
	},
	methods: {
		completeIntroVideoStep() {
			this.$emit('completeIntroVideoStep');
		},
		completeEditoolStep() {
			this.$emit('completeEditoolStep');
		},
		getUserAchievementLevels(achievement) {
			const levelIds = achievement.levels.map(l => l.id);
			return this.userAchievementLevels.filter(ual => levelIds.includes(ual.achievement_level_id));
		},
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
a.box {
	transition: all 250ms ease;

	&:hover {
		margin-top: -0.3rem !important;
		margin-bottom: 0.3rem !important;
		+ a.box {
			margin-top: 0.3rem !important;
		}
	}
}
.title {
	&.is-strikethrough {
		text-decoration: line-through;
	}
}
.tile {
	&.news-tile {
		padding: 0;

		.news-tile-header {
			border-top-left-radius: $radius-large;
			border-top-right-radius: $radius-large;
		}
		.news-tile-list {
			border-bottom-left-radius: $radius-large;
			border-bottom-right-radius: $radius-large;

			a {
				display: block;

				.news-date {
					color: $grey;
				}

				&:hover {
					.news-title {
						color: $blue;
					}
				}
			}
		}
	}
}
</style>
