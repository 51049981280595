<template>
	<HomeworkCardContainer
		class="box"
		:class="{ 'is-clickable': clickable, 'is-hoverable': hoverable, 'is-centered': centered }"
		footer-has-background
		@click.native="onClick"
	>
		<template #image>
			<img v-if="homework.image_url" class="homework-card-image" :src="homework.image_url" />
			<BaseSkeleton
				v-else
				rounded
				width="100%"
				height="10rem"
				class="homework-card-image"
				:animate="false"
				:color="homework.course.color"
			/>
		</template>
		<template #schoolclass>
			<BaseTag size="normal" color="black" text-color="white">{{ classText | truncate(10) }}</BaseTag>
		</template>
		<template #course>
			<CourseFlag v-if="homework.course" :course="homework.course" />
		</template>

		<template #title>{{ homework.title | truncate(45) }}</template>
		<template #source>
			<BaseTag size="normal" color="grey-lighterer" text-color="grey">{{ sourceTitle }}</BaseTag>
		</template>

		<template #deadline>
			<div class="is-flex is-align-items-center">
				<BaseIcon icon="calendar" color="grey-light" :size="3" no-padding />
				<span class="is-size-9 has-text-grey-dark">{{
					homework.deadline_at | dateFormat('DD/MM/YY')
				}}</span>
			</div>
		</template>
		<template #duration>
			<div class="is-flex is-align-items-center">
				<BaseIcon icon="clock" color="grey-light" :size="3" no-padding />
				<span class="is-size-9 has-text-grey-dark">{{ homeworkDuration }}</span>
			</div>
		</template>

		<template #footer>
			<BaseProgressBar :progress="progress" />
			<BaseIcon
				v-if="isCompleted"
				icon="check"
				color="green"
				:size="3"
				class="ml-2"
				show-background
			/>
		</template>
	</HomeworkCardContainer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import math from '@/mixins/filters/math';
import date from '@/mixins/filters/date';
import string from '@/mixins/filters/string';
import HomeworkCardContainer from './HomeworkCardContainer';
import CourseFlag from '@/components/course/CourseFlag';
import BaseTag from '@/components/base/BaseTag';
import BaseIcon from '@/components/base/BaseIcon';
import BaseProgressBar from '@/components/base/BaseProgressBar';
import BaseSkeleton from '@/components/base/load/BaseSkeleton';
import { timeHumanized } from '@/utils/date';

export default {
	name: 'HomeworkCard',
	components: {
		HomeworkCardContainer,
		CourseFlag,
		BaseTag,
		BaseIcon,
		BaseProgressBar,
		BaseSkeleton,
	},
	mixins: [math, date, string],
	props: {
		centerText: {
			type: String,
			default: () => '',
		},
		homework: {
			type: Object,
			required: true,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		hoverable: {
			type: Boolean,
			default: true,
		},
		centered: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('user', ['isTeacher']),
		...mapState('user', ['schoolId']),
		homeworkDuration() {
			let duration;
			if (this.homework?.type == 'screening') {
				const released_at = dayjs(this.homework.released_at);
				const deadline_at = dayjs(this.homework.deadline_at);
				duration = deadline_at.diff(released_at, 'minute');
			} else if (this.homework?.type == 'training') {
				duration = this.homework.training_duration;
			} else {
				duration = this.homework.duration;
			}
			if (duration > 720) {
				return '--';
			}
			return timeHumanized(duration);
		},
		classText() {
			return this.centerText ? this.centerText : this.homework.school_class.name;
		},
		sourceTitle() {
			if (this.homework?.type === 'training') {
				return 'Adaptiv træning';
			}
			if (this.homework?.type === 'grammar' && this.homework?.parents?.length > 0) {
				return this.homework.parents[0].title;
			}
			if (this.homework?.course) {
				return this.homework.course.title;
			}

			return 'Lektie';
		},
		progress() {
			return this.homework?.progress ?? 0;
		},
		isCompleted() {
			return this.progress >= 100;
		},
	},
	methods: {
		onClick(event) {
			if (!this.clickable) {
				return;
			}
			this.$emit('click', event);
		},
	},
};
</script>
