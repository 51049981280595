var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"is-flex is-align-items-center mb-4"},[_c('BaseIcon',{attrs:{"icon":"scroll","color":"blue","no-padding":""}}),_c('p',{staticClass:"title is-5 ml-3"},[_vm._v("Kom godt i gang")])],1),_c('p',{staticClass:"subtitle is-6"},[_vm._v("Følg disse fem trin for at få det optimale ud af din adgang til Minlæring.")]),_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-vertical"},[_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tile is-parent is-8"},[_c('TeacherSuperUserVideoTile',{attrs:{"type":"div","number":1,"title":"Velkommen","completed":_vm.progress.has_clicked_intro_video,"highlighted":_vm.firstIncompleteNumber === 1},nativeOn:{"click":function($event){return _vm.completeIntroVideoStep.apply(null, arguments)}}})],1),_c('div',{staticClass:"tile is-parent"},[_c('TeacherSuperUserImageTile',{attrs:{"type":"router-link","number":2,"path":{
							name: 'school_classes',
						},"title":"Opret hold","completed":_vm.progress.has_created_schoolclass,"highlighted":_vm.firstIncompleteNumber === 2,"default-image":"/images/superuser/schoolclass.svg","active-image":"/images/superuser/schoolclass_active.svg"}})],1)]),_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tile is-parent"},[_c('TeacherSuperUserImageTile',{attrs:{"type":"router-link","number":3,"path":{
							name: 'homework',
						},"title":"Opret lektie","completed":_vm.progress.has_created_homework,"highlighted":_vm.firstIncompleteNumber === 3,"default-image":"/images/superuser/homework.svg","active-image":"/images/superuser/homework_active.svg"}})],1),_c('div',{staticClass:"tile is-parent"},[_c('TeacherSuperUserImageTile',{attrs:{"type":"a","href":"https://www.fejlretter.dk/inspiration-til-dit-rettearbejde","number":4,"title":"Tilføj Fejlretter","completed":_vm.progress.has_clicked_editool_link,"highlighted":_vm.firstIncompleteNumber === 4,"default-image":"/images/superuser/editool.svg","active-image":"/images/superuser/editool_active.svg"},nativeOn:{"click":function($event){return _vm.completeEditoolStep.apply(null, arguments)}}})],1),_c('div',{staticClass:"tile is-parent"},[_c('TeacherSuperUserImageTile',{attrs:{"type":"router-link","number":5,"path":{
							name: 'profile',
						},"title":"Bekræft oplysninger","completed":_vm.progress.has_confirmed_information,"highlighted":_vm.firstIncompleteNumber === 5,"default-image":"/images/superuser/information.svg","active-image":"/images/superuser/information_active.svg"}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }