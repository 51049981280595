<template>
	<div>
		<div class="is-flex is-align-items-center mb-4">
			<BaseIcon icon="doc-correct" color="blue" no-padding />
			<p class="title is-5 ml-3">Lektier</p>
		</div>

		<BaseTabs v-model="activeTab">
			<BaseTab v-for="(homeworkTab, index) in homeworkTabs" :id="index" :key="index">
				<template #title>{{ homeworkTab.title }}</template>
				<template v-if="homeworkTab.homework_count > 0" #badge>
					<BaseTag :color="activeTab == index ? 'blue' : 'grey-light'" class="ml-2">
						{{ homeworkTab.homework_count }}
					</BaseTag>
				</template>
			</BaseTab>
		</BaseTabs>
		<BaseTabsContainer :active="activeTab">
			<BaseTabContent v-for="(homeworkTab, index) in homeworkTabs" :id="index" :key="index">
				<div
					v-if="homeworkTab.homework_count == 0"
					class="columns is-vcentered has-text-centered"
				>
					<div class="column mt-6">
						<img src="/images/homework/homework-card.svg" width="200" />
						<p class="title is-5 has-text-grey mt-4">Ingen lektier</p>
					</div>
				</div>
				<div v-else>
					<div class="columns is-mobile is-multiline is-variable is-4">
						<div
							v-for="homeworkItem in homeworkTab.homework"
							:key="`homework-${homeworkItem.id}`"
							class="column is-12-mobile is-6-tablet is-4-desktop mb-5"
						>
							<template v-if="isStudent">
								<router-link
									v-if="homeworkItem.type == 'screening'"
									:to="getStudentScreeningRoute(homeworkItem)"
								>
									<HomeworkCard
										:homework="homeworkItem"
										centered
									/>
								</router-link>
								<router-link
									v-else-if="homeworkItem.type == 'training'"
									:to="getStudentTrainingRoute(homeworkItem)"
								>
									<HomeworkCard
										:homework="homeworkItem"
										centered
									/>
								</router-link>
								<HomeworkCard
									v-else
									:homework="homeworkItem"
									:clickable="true"
									centered
									@click="() => onHomeworkCardClick(homeworkItem)"
								/>
							</template>

							<router-link v-else :to="getTeacherHomeworkRoute(homeworkItem)">
								<HomeworkCard :homework="homeworkItem" centered />
							</router-link>
						</div>
					</div>
				</div>
			</BaseTabContent>
		</BaseTabsContainer>

		<HomeworkModal
			v-if="isStudent && selectedHomework"
			:homework="selectedHomework"
			@hide="onHomeworkModalHide"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import HomeworkCard from '@/components/homework/HomeworkCard';
import BaseTabs from '@/components/base/tabs/BaseTabs';
import BaseTab from '@/components/base/tabs/BaseTab';
import BaseTabsContainer from '@/components/base/tabs/BaseTabsContainer';
import BaseTabContent from '@/components/base/tabs/BaseTabContent';
import BaseTag from '@/components/base/BaseTag';
import BaseIcon from '@/components/base/BaseIcon';
import HomeworkModal from '@/components/homework/modal/HomeworkModal';

export default {
	name: 'HomeworkTabs',
	components: {
		HomeworkCard,
		HomeworkModal,
		BaseTabs,
		BaseTab,
		BaseTabsContainer,
		BaseTabContent,
		BaseTag,
		BaseIcon,
	},
	props: {
		homeworkTabs: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			activeTab: 'this_week',
			selectedHomework: null,
		};
	},
	computed: {
		...mapGetters('user', ['isStudent']),
	},
	created() {
		if (
			this.homeworkTabs.this_week.homework_count === 0 &&
			this.homeworkTabs.next_week.homework_count !== 0
		) {
			this.activeTab = 'next_week';
		}
	},
	methods: {
		getStudentScreeningRoute(homeworkItem) {
			return {
				name: 'screening',
				params: {
					id: homeworkItem.parents[0].id,
				},
				query: { homeworkId: homeworkItem.id },
			};
		},
		getStudentTrainingRoute(homeworkItem) {
			return {
				name: 'training_homework',
				params: { id: homeworkItem.id },
			};
		},
		getTeacherHomeworkRoute(homeworkItem) {
			const routePrefix = ['screening', 'training'].includes(homeworkItem.type)
				? `${homeworkItem.type}_`
				: '';
			return {
				name: `${routePrefix}homework_details`,
				params: {
					school_class_id: homeworkItem.school_class.id,
					homework_id: homeworkItem.id,
				},
			};
		},
		onHomeworkCardClick(homeworkItem) {
			this.selectedHomework = homeworkItem;
		},
		onHomeworkModalHide() {
			this.selectedHomework = null;
		},
	},
};
</script>
