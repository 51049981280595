<template>
	<div class="modal" :class="{ 'is-active': active }">
		<div class="modal-background" :class="animateCss(['fadeIn'])" @click="hide"></div>

		<div class="modal-content" :class="animateCss(['fadeInUp', 'faster', 'delay-1s'])">
			<div class="columns is-mobile">
				<div class="column modal-left is-hidden-mobile" :class="leftColumnClass">
					<slot name="img"></slot>
				</div>
				<div class="column modal-right has-background-white" :class="rightColumnClass">
					<div class="modal-body has-text-left">
						<p class="title is-3">{{ title }}</p>
						<slot name="body"></slot>
					</div>
					<div class="modal-footer has-background-grey-lighterer">
						<slot name="footer"></slot>
					</div>
				</div>
			</div>
		</div>
		<button v-if="dismissible" class="modal-close is-large" aria-label="close" @click="hide" />
	</div>
</template>

<script>
import animateCss from '@/mixins/animateCss';

export default {
	name: 'SplitModal',
	mixins: [animateCss],
	props: {
		title: {
			type: String,
			default: '',
		},
		dismissible: {
			type: Boolean,
			default: true,
		},
		imageWidth: {
			type: Number,
			default: 6,
		},
		leftColumnClasses: {
			type: [Array, Object],
			required: false,
			default: () => [],
		},
	},
	data() {
		return {
			active: false,
		};
	},
	computed: {
		leftColumnClass() {
			const leftColumnClass = this.leftColumnClasses;
			leftColumnClass.push(`is-${this.imageWidth}`);
			return leftColumnClass;
		},
		rightColumnClass() {
			const width = 12 - this.imageWidth;
			return [
				'is-12-mobile',
				`is-${width}-tablet`,
				`is-${width}-desktop`,
				`is-${width}-widescreen`,
				`is-${width}-fullhd`,
			];
		},
	},
	beforeDestroy() {
		this.stopListeningForKeystrokes();
	},
	methods: {
		show() {
			this.active = true;
			if (this.dismissible) {
				this.startListeningForKeystrokes();
			}
		},
		hide() {
			if (!this.dismissible) {
				return;
			}
			this.stopListeningForKeystrokes();
			this.$emit('hide');
			this.active = false;
		},
		onBodyKeyDown(event) {
			const key = typeof event.key === 'string' ? event.key.toLowerCase() : '';
			if (key === 'escape') {
				this.hide();
			}
		},
		startListeningForKeystrokes() {
			document.addEventListener('keydown', this.onBodyKeyDown);
		},
		stopListeningForKeystrokes() {
			document.removeEventListener('keydown', this.onBodyKeyDown);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal {
	.modal-content {
		.columns {
			// Note: Same as $modal-background-background-color
			// but without opacity.
			background-color: rgba(31, 35, 58, 1);
			margin: 0;
			max-height: inherit;
			min-height: inherit;

			.modal-left {
				padding: 0;
				overflow: hidden;

				&:not(.has-fixed-img) {
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}

			.modal-right {
				padding: 0;
				position: relative;
				display: flex;
				max-height: inherit;
				min-height: inherit;

				.modal-header {
					position: fixed;
					top: 0;
					width: inherit;
					height: 64px;
				}

				.modal-body {
					margin-top: 64px;
					margin-bottom: 80px;
					padding: 20px 10%;
					overflow: auto;
					width: 100%;
				}

				.modal-footer {
					position: fixed;
					bottom: 0;
					width: inherit;
					border-radius: 0 0 30px 0;
				}

				.columns {
					background-color: white;
				}
			}
		}
	}
}
</style>
