<template>
	<div class="homework-card">
		<div class="homework-card-main">
			<div class="homework-card-image-container">
				<slot name="image" />
				<div
					class="is-flex is-align-items-center is-justify-content-space-between is-relative p-3"
				>
					<div>
						<slot name="schoolclass" />
					</div>
					<div>
						<slot name="course" />
					</div>
				</div>
			</div>

			<div
				class="homework-card-data is-flex is-flex-direction-column is-justify-content-space-between mt-3"
			>
				<div class="has-text-centered">
					<p class="title is-6 mb-2">
						<slot name="title" />
					</p>
					<slot name="source" />
				</div>

				<div class="is-flex is-align-items-center is-justify-content-space-between">
					<slot name="deadline" />
					<div class="vertical-separator"></div>
					<slot name="duration" />
				</div>
			</div>
		</div>

		<div class="homework-card-footer is-flex is-align-items-center" :class="footerClasses">
			<slot name="footer" />
		</div>

		<slot />
	</div>
</template>

<script>
export default {
	name: 'HomeworkCardContainer',
	props: {
		footerHasBackground: {
			type: Boolean,
			default: false,
		},
		footerBorderColor: {
			type: String,
			default: '',
		},
	},
	computed: {
		footerClasses() {
			const classNames = [];
			if (this.footerHasBackground) {
				classNames.push('has-background-color');
			}
			if (this.footerBorderColor) {
				classNames.push(`has-border-${this.footerBorderColor}`);
			}
			return classNames;
		},
	},
};
</script>
