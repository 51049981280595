<template>
	<div class="slider" :style="{ height: `${height}px` }">
		<transition-group v-if="currentItem" name="fade" tag="div" class="items">
			<div v-for="i in [currentIndex]" :key="i">
				<p class="title is-5">{{ currentItem.title }}</p>
				<p>{{ currentItem.content }}</p>
			</div>
		</transition-group>

		<div class="controls">
			<div class="is-flex is-align-items-center">
				<span class="control mr-3" @click="previous">
					<BaseIcon
						icon="chevron-left"
						active-color="blue"
						color="grey-light"
						:size="6"
						:show-background="true"
						clickable
					/>
				</span>
				<span class="control" @click="next">
					<BaseIcon
						icon="chevron-right"
						active-color="blue"
						color="grey-light"
						:size="6"
						:show-background="true"
						clickable
					/>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';

export default {
	name: 'BaseSlider',
	components: { BaseIcon },
	props: {
		items: {
			type: Array,
			required: true,
		},
		height: {
			type: Number,
			default: () => 200,
		},
		autoSlide: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			timer: null,
			currentIndex: 0,
		};
	},
	computed: {
		currentItem() {
			return this.items.length ? this.items[Math.abs(this.currentIndex) % this.items.length] : null;
		},
	},
	watch: {
		items: {
			handler() {
				this.init();
			},
			immediate: true,
		},
	},
	methods: {
		init() {
			if (this.timer) {
				clearInterval(this.timer);
			}
			if (!this.items.length) {
				return;
			}
			if (this.autoSlide) {
				this.startSlide();
			} else {
				this.currentIndex = Math.floor(Math.random() * this.items.length);
			}
		},
		startSlide() {
			this.timer = setInterval(this.next, 5000);
		},
		next() {
			this.currentIndex += 1;
		},
		previous() {
			this.currentIndex -= 1;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';

.slider {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.items {
		position: relative;
	}
	.controls {
		margin-top: auto;

		.control {
			cursor: pointer;
			user-select: none;
		}
	}

	@include mixin-touch-only {
		height: auto !important;
	}
}
.fade-enter-active,
.fade-leave-active {
	transition: all 0.9s ease;
	visibility: visible;
	position: absolute;
	opacity: 1;
}
.fade-enter,
.fade-leave-to {
	visibility: hidden;
	opacity: 0;
}
</style>
