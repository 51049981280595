<template>
	<div>
		<div class="is-flex is-align-items-center mb-4">
			<BaseIcon icon="scroll" color="blue" no-padding />
			<p class="title is-5 ml-3">Kom godt i gang</p>
		</div>
		<p class="subtitle is-6">Følg disse fem trin for at få det optimale ud af din adgang til Minlæring.</p>

		<div class="tile is-ancestor">
			<div class="tile is-vertical">
				<div class="tile">
					<div class="tile is-parent is-8">
						<TeacherSuperUserVideoTile
							type="div"
							:number="1"
							title="Velkommen"
							:completed="progress.has_clicked_intro_video"
							:highlighted="firstIncompleteNumber === 1"
							@click.native="completeIntroVideoStep"
						/>
					</div>
					<div class="tile is-parent">
						<TeacherSuperUserImageTile
							type="router-link"
							:number="2"
							:path="{
								name: 'school_classes',
							}"
							title="Opret hold"
							:completed="progress.has_created_schoolclass"
							:highlighted="firstIncompleteNumber === 2"
							default-image="/images/superuser/schoolclass.svg"
							active-image="/images/superuser/schoolclass_active.svg"
						/>
					</div>
				</div>

				<div class="tile">
					<div class="tile is-parent">
						<TeacherSuperUserImageTile
							type="router-link"
							:number="3"
							:path="{
								name: 'homework',
							}"
							title="Opret lektie"
							:completed="progress.has_created_homework"
							:highlighted="firstIncompleteNumber === 3"
							default-image="/images/superuser/homework.svg"
							active-image="/images/superuser/homework_active.svg"
						/>
					</div>
					<div class="tile is-parent">
						<TeacherSuperUserImageTile
							type="a"
							href="https://www.fejlretter.dk/inspiration-til-dit-rettearbejde"
							:number="4"
							title="Tilføj Fejlretter"
							:completed="progress.has_clicked_editool_link"
							:highlighted="firstIncompleteNumber === 4"
							default-image="/images/superuser/editool.svg"
							active-image="/images/superuser/editool_active.svg"
							@click.native="completeEditoolStep"
						/>
					</div>
					<div class="tile is-parent">
						<TeacherSuperUserImageTile
							type="router-link"
							:number="5"
							:path="{
								name: 'profile',
							}"
							title="Bekræft oplysninger"
							:completed="progress.has_confirmed_information"
							:highlighted="firstIncompleteNumber === 5"
							default-image="/images/superuser/information.svg"
							active-image="/images/superuser/information_active.svg"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';
import TeacherSuperUserImageTile from './TeacherSuperUserImageTile';
import TeacherSuperUserVideoTile from './TeacherSuperUserVideoTile';

export default {
	name: 'TeacherSuperUser',
	components: {
		BaseIcon,
		TeacherSuperUserImageTile,
		TeacherSuperUserVideoTile,
	},
	props: {
		progress: {
			type: Object,
			required: true,
		},
	},
	computed: {
		firstIncompleteNumber() {
			const keys = [
				'has_clicked_intro_video',
				'has_created_schoolclass',
				'has_created_homework',
				'has_clicked_editool_link',
				'has_confirmed_information',
			];

			let n = 0;
			for (const key of keys) {
				n++;
				if (!this.progress[key]) {
					return n;
				}
			}
			return -1;
		},
	},
	methods: {
		completeIntroVideoStep() {
			this.$emit('completeIntroVideoStep');
		},
		completeEditoolStep() {
			this.$emit('completeEditoolStep');
		},
	},
};
</script>
