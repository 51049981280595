<template>
	<div>
		<div class="is-flex is-align-items-center mb-5">
			<BaseIcon icon="cateye" color="purple" no-padding />
			<p class="title is-5 ml-3">Udforsk</p>
		</div>

		<div v-if="unfinishedExercises.length > 0" class="tile is-ancestor">
			<div class="tile is-parent">
				<div class="tile is-child box">
					<div class="is-flex is-align-items-center mb-5">
						<BaseIcon icon="flame" color="red" :show-background="true" :size="5" />
						<p class="title is-6 ml-3">Fortsæt</p>
					</div>
					<StudentExerciseProgress
						v-for="(exercise, i) in unfinishedExercises"
						:key="`continue-${i}`"
						:section="exercise.section"
						:homework="exercise.homework"
						:progress="exercise.progress"
						:course-id="exercise.course_id ?? 0"
					/>
				</div>
			</div>
		</div>

		<div class="tile is-ancestor">
			<div class="tile is-parent">
				<div class="tile is-child box">
					<div class="is-flex is-align-items-center mb-5">
						<BaseIcon
							icon="star"
							color="yellow"
							:show-background="true"
							:size="5"
						/>
						<p class="title is-6 ml-3">Tip</p>
					</div>
					<BaseSlider :items="tips" :height="280" />
				</div>
			</div>
			<div class="tile is-parent">
				<div
					class="tile is-child box student-video-box is-clickable"
					@click="completeIntroVideoStep"
				>
					<div class="is-flex is-align-items-center mb-4">
						<BaseIcon icon="flag" color="green" :size="5" show-background />
						<p class="title is-6 ml-3 has-text-white">Kom godt i gang</p>
					</div>
					<div class="play">
						<BaseIcon icon="play" color="blue" :size="5" show-background />
					</div>
				</div>
			</div>
		</div>

		<div v-if="achievements.length > 0">
			<div class="is-flex is-align-items-center mb-5">
				<BaseIcon icon="achievements" color="yellow" no-padding />
				<p class="title is-5 ml-3">Bedrifter</p>
			</div>

			<div class="columns is-multiline is-variable">
				<div v-for="(achievement, index) in achievements" :key="index" class="column is-4">
					<AchievementCard
						:achievement="achievement"
						:user-achievement-levels="getUserAchievementLevels(achievement)"
					/>
				</div>
			</div>
		</div>

		<LightModal ref="introVideoModal" :width="800">
			<VimeoVideo video-id="295621010" />
		</LightModal>
	</div>
</template>

<script>
import AchievementCard from '@/components/dashboard/AchievementCard';
import StudentExerciseProgress from '@/components/dashboard/StudentExerciseProgress';
import BaseIcon from '@/components/base/BaseIcon';
import BaseSlider from '@/components/base/BaseSlider';
import LightModal from '@/components/ui/modal/LightModal';
import VimeoVideo from '@/components/ui/embed/VimeoVideo';

export default {
	name: 'ExploreStudent',
	components: {
		AchievementCard,
		StudentExerciseProgress,
		BaseIcon,
		BaseSlider,
		LightModal,
		VimeoVideo,
	},
	props: {
		achievements: {
			type: Array,
			default: () => [],
		},
		userAchievementLevels: {
			type: Array,
			default: () => [],
		},
		unfinishedExercises: {
			type: Array,
			default: () => [],
		},
		tips: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		completeIntroVideoStep() {
			this.$refs.introVideoModal.show();
		},
		getUserAchievementLevels(achievement) {
			const levelIds = achievement.levels.map(l => l.id);
			return this.userAchievementLevels.filter(ual => levelIds.includes(ual.achievement_level_id));
		},
	},
};
</script>
<style lang="scss" scoped>
.student-video-box {
	transition: all 250ms ease;

	background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
		url('/public/images/explorer/video-preview.png');
	background-size: cover;
	background-position: right;

	&:hover {
		margin-top: -0.3rem !important;
		margin-bottom: 0.3rem !important;
	}
}
.play {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 190px;
}
</style>
