<template>
	<article class="message teacher-message" :class="[`has-background-${color}`, `has-text-${textColor}`]">
		<div class="message-body" :class="`has-text-${textColor}`">
			<div class="is-flex is-align-items-center is-justify-content-space-between mb-4">
				<div class="is-flex is-align-items-center">
					<Avatar class="teacher-avatar mr-3" :avatar="teacher.avatar" />
					<div>
						<p class="title mb-0" :class="`has-text-${textColor}`">
							{{ teacher.name }}
						</p>
						<div v-if="updatedAt">{{ formatDateTime(updatedAt) }}</div>
					</div>
				</div>
				<div class="is-flex is-align-items-center">
					<BaseIcon
						v-if="editable"
						v-tippy="{ content: 'Rediger' }"
						icon="cog"
						color="white"
						clickable
						@click.native="$emit('edit')"
					/>
				</div>
			</div>
			<div class="is-size-6" style="white-space: pre-wrap" v-html="message" />
		</div>
	</article>
</template>

<script>
import Avatar from '@/components/ui/user/Avatar.vue';
import BaseIcon from '@/components/base/BaseIcon';
import { formatDateTime } from '@/utils/date';

export default {
	name: 'TeacherMessage',
	components: {
		Avatar,
		BaseIcon,
	},
	props: {
		teacher: {
			type: Object,
			required: true,
		},
		message: {
			type: String,
			required: true,
		},
		updatedAt: {
			type: String,
			default: '',
		},
		editable: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: 'grey-lighter',
		},
		textColor: {
			type: String,
			default: 'black',
		},
	},
	methods: {
		formatDateTime,
	},
};
</script>

<style lang="scss" scoped>
.teacher-message {
	.message-body {
		border: none;
	}
}
</style>
