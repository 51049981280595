<template>
	<div>
		<BaseLoader v-if="loading" size="large" class="mt-6" />

		<div v-else class="columns is-desktop">
			<div class="column is-3-desktop">
				<Profile :user="user" :overall-stats="statistics" with-profile-link />
			</div>
			<div class="column is-9-desktop">
				<HomeworkTabs v-if="hasHomework" :homework-tabs="homeworkTabs" />
				<ExploreStudent
					v-if="isStudent"
					class="mt-6"
					:achievements="achievements"
					:user-achievement-levels="userAchievementLevels"
					:tips="tips"
					:unfinished-exercises="unfinishedExercises"
				/>
				<TeacherSuperUser
					v-if="!isStudent && !isSuperUser && teacherProgress"
					ref="superUser"
					class="mt-6"
					:progress="teacherProgress"
					@completeIntroVideoStep="completeIntroVideoStep"
					@completeEditoolStep="completeEditoolStep"
				/>
				<ExploreTeacher
					v-if="!isStudent && teacherProgress"
					class="mt-6"
					:achievements="achievements"
					:user-achievement-levels="userAchievementLevels"
					:news="news"
					:tips="tips"
					:progress="teacherProgress"
					:is-super-user="isSuperUser"
					@completeIntroVideoStep="completeIntroVideoStep"
					@completeEditoolStep="completeEditoolStep"
				/>
			</div>
		</div>

		<LightModal ref="introVideoModal" :width="800">
			<VimeoVideo v-if="schoolIsPrimarySchool" video-id="673563328" />
			<VimeoVideo v-else video-id="552430591" />
		</LightModal>
	</div>
</template>

<script>
import BaseLoader from '@/components/base/BaseLoader';
import TeacherSuperUser from '@/components/dashboard/TeacherSuperUser';
import ExploreTeacher from '@/components/dashboard/ExploreTeacher';
import ExploreStudent from '@/components/dashboard/ExploreStudent';
import HomeworkTabs from '@/components/dashboard/HomeworkTabs';
import Profile from '@/components/user/Profile';
import Section from '@/models/section/Section';
import LightModal from '@/components/ui/modal/LightModal';
import VimeoVideo from '@/components/ui/embed/VimeoVideo';
import { mapGetters } from 'vuex';

export default {
	name: 'DashboardView',
	components: {
		BaseLoader,
		HomeworkTabs,
		TeacherSuperUser,
		ExploreTeacher,
		ExploreStudent,
		Profile,
		LightModal,
		VimeoVideo,
	},
	data() {
		return {
			loading: false,
			hasHomework: false,
			student: null,
			homeworkTabs: [],
			statistics: [],
			achievements: [],
			userAchievementLevels: [],
			tips: [],
			news: [],
			unfinishedExercises: [],
			teacherProgress: null,
		};
	},
	computed: {
		...mapGetters('user', ['user', 'isStudent', 'schoolIsPrimarySchool']),
		isSuperUser() {
			return (
				this.teacherProgress &&
				Object.keys(this.teacherProgress).filter(key => !this.teacherProgress[key]).length === 0
			);
		},
	},
	created() {
		this.loading = true;

		const requests = [this.loadDashboardData()];
		if (this.isStudent) {
			requests.push(this.loadUserLatestUnfinishedExercises());
		} else {
			requests.push(this.loadUserProgress());
		}
		Promise.all(requests).finally(() => (this.loading = false));
	},
	methods: {
		loadDashboardData() {
			return this.$store.dispatch('user/getDashboard').then(response => {
				this.hasHomework = response.has_any_homework;
				this.achievements = response.achievements;
				this.userAchievementLevels = response.user_achievement_levels;
				this.statistics = this.getStatisticsFromAchievements(response.user_achievement_levels);
				this.homeworkTabs = response.homework_tabs;
				this.tips = response.tips;
				this.news = response.news;
			});
		},
		getStatisticsFromAchievements(userAchievementLevels) {
			const res = {
				difficulties: [
					{
						machine_name: 'exercises',
						value: 0,
						filters: { type: 'completed_exercises', difficulty: null },
					},
					{
						machine_name: 'difficulty1',
						value: 0,
						filters: { type: 'completed_exercises', difficulty: 1 },
					},
					{
						machine_name: 'difficulty2',
						value: 0,
						filters: { type: 'completed_exercises', difficulty: 2 },
					},
					{
						machine_name: 'difficulty3',
						value: 0,
						filters: { type: 'completed_exercises', difficulty: 3 },
					},
					{
						machine_name: 'exam',
						value: 0,
						filters: { type: 'completed_exercises', difficulty: 4 },
					},
				],
				points: [
					{
						machine_name: 'points',
						value: 0,
						filters: { type: 'points', difficulty: null },
					},
				],
			};
			const achievementLevels = this.achievements.reduce((acc, a) => acc.concat(a.levels), []);
			for (let i = 0; i < Object.keys(res).length; i++) {
				const k = Object.keys(res)[i];
				const stat = res[k];
				for (let j = 0; j < stat.length; j++) {
					const e = stat[j];
					const achievementLevel = achievementLevels.find(
						ual =>
							ual.type === e.filters.type &&
							ual.difficulty === e.filters.difficulty,
					);
					if (!achievementLevel) {
						continue;
					}
					const userAchievementLevel = userAchievementLevels.find(
						ual => ual.achievement_level_id === achievementLevel.id,
					);
					if (userAchievementLevel) {
						res[k][j].value = userAchievementLevel.amount;
					}
				}
			}
			res.points = res.points[0];
			delete res.points[0];
			return res;
		},
		loadUserProgress() {
			return this.$store
				.dispatch('activity/getUserProgress')
				.then(response => (this.teacherProgress = response));
		},
		loadUserLatestUnfinishedExercises() {
			return this.$store.dispatch('results/getUserLatestUnfinishedExercises').then(entries => {
				this.unfinishedExercises = entries.map(entry => {
					entry.section = new Section(entry.section);
					return entry;
				});
			});
		},
		logActivity(action) {
			return this.$store.dispatch('activity/logActivity', {
				action: action,
			});
		},
		completeIntroVideoStep() {
			this.$refs.introVideoModal.show();
			this.logActivity('clicked_intro_video').finally(this.loadUserProgress);
		},
		completeEditoolStep() {
			this.logActivity('clicked_editool_link').finally(this.loadUserProgress);
		},
	},
};
</script>
