<template>
	<TeacherSuperUserTile
		:type="type"
		:number="number"
		:title="title"
		:path="path"
		:href="href"
		:completed="completed"
		:highlighted="highlighted"
		@mouseover.native="hover = true"
		@mouseleave.native="hover = false"
	>
		<div class="image" :class="imageClasses" :style="{ 'background-image': `url(${imgSrc})` }" />
	</TeacherSuperUserTile>
</template>

<script>
import TeacherSuperUserTile from './TeacherSuperUserTile';

export default {
	name: 'TeacherSuperUserImageTile',
	components: {
		TeacherSuperUserTile,
	},
	props: {
		number: {
			type: Number,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		completed: {
			type: Boolean,
			default: false,
		},
		highlighted: {
			type: Boolean,
			default: false,
		},
		defaultImage: {
			type: String,
			required: true,
		},
		activeImage: {
			type: String,
			required: true,
		},
		path: {
			type: Object,
			required: false,
			default: null,
		},
		type: {
			type: String,
			required: true,
		},
		href: {
			type: String,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			hover: false,
		};
	},
	computed: {
		imageClasses() {
			return {
				animated: navigator.appVersion.indexOf('Chrome/') != -1,
			};
		},
		imgSrc() {
			if (!this.completed && this.hover) {
				return this.activeImage;
			}
			return !this.highlighted && !this.completed ? this.defaultImage : this.activeImage;
		},
	},
};
</script>
<style lang="scss" scoped>
.image {
	min-height: 240px;
	background-repeat: no-repeat;
	background-position: center;

	&.animated {
		transition: background-image 250ms ease;
	}
}
</style>
