<template>
	<img :src="url" class="exercise-icon is-flex-shrink-0" :class="cssClasses" />
</template>

<script>
export default {
	name: 'ExerciseIcon',
	props: {
		type: {
			type: String,
			required: true,
		},
		size: {
			type: String,
			default: 'medium',
		},
		active: {
			type: Boolean,
			default: true,
		},
		stroked: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		cssClasses() {
			return [`is-${this.size}`];
		},
		url() {
			const folder = '/images/icons/exercise/';
			if (!this.active) {
				return `${folder}${this.type}-inactive.svg`;
			}
			return `${folder}${this.type}${this.stroked ? '-stroked' : ''}.svg`;
		},
	},
};
</script>

<style lang="scss" scoped>
.exercise-icon {
	width: 3rem;
	height: 3rem;

	&.is-small {
		width: 1rem;
		height: 1rem;
	}
	&.is-medium {
		width: 2rem;
		height: 2rem;
	}
	&.is-large {
		width: 3rem;
		height: 3rem;
	}
	&.is-xlarge {
		width: 6rem;
		height: 6rem;
	}
}
</style>
