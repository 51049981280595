<template>
	<div class="box user-profile has-text-centered">
		<div class="columns is-mobile is-vcentered is-multiline">
			<div class="column is-6-mobile is-6-tablet is-12-desktop">
				<Avatar :avatar="user.avatar" />
			</div>
			<div class="column is-6-mobile is-6-tablet is-12-desktop">
				<p class="title is-5 has-text-black mt-4 mb-2">{{ user.name }}</p>
				<p v-if="school" class="title has-text-grey-dark is-7">{{ school.name }}</p>
				<p v-if="user.unilogin_id" class="subtitle has-text-grey-dark is-7">
					{{ user.unilogin_id }}
				</p>

				<div v-if="withStudentResetLink" class="mb-5">
					<button class="button is-medium" @click="showStudentReset">
						Nulstil login
					</button>
				</div>
				<div v-if="withProfileLink" class="mb-5">
					<div class="mb-5">
						<router-link
							:to="{ name: 'profile' }"
							class="button is-medium is-primary"
							>Profil</router-link
						>
					</div>
				</div>
			</div>
		</div>

		<div v-if="overallStats" class="student-stats py-5 has-background-grey-lightest">
			<div
				v-for="(group, groupIndex) in statDifficultyGroups"
				:key="`stats-group-${groupIndex}`"
				class="is-flex is-justify-content-space-evenly mb-5 mt-4"
			>
				<div
					v-for="(item, itemIndex) in group"
					:key="`stats-group-${groupIndex}-item-${itemIndex}`"
					class="student-stats-item is-flex is-align-items-center"
				>
					<div class="pr-1">
						<ExerciseIcon :type="item.icon" />
					</div>
					<div class="student-stats-item-value has-text-left">
						<p class="title has-text-grey is-8 mb-0">{{ item.title }}</p>
						<p class="subtitle has-text-black is-6 mt-0">
							<b>{{ item.value }}</b>
						</p>
					</div>
				</div>
			</div>

			<hr class="my-5" />

			<div
				v-if="overallStats.points"
				class="is-flex is-justify-content-center is-align-items-center is-align-content-center"
			>
				<div class="pr-2">
					<ExerciseIcon type="points" size="large" />
				</div>
				<div class="student-stats-item-value has-text-left">
					<p class="title has-text-grey is-8 mb-0">Point</p>
					<p class="title has-text-black is-5">
						<b>{{ formatLargeNumber(round(overallStats.points.value)) }}</b>
					</p>
				</div>
			</div>
		</div>

		<ResetStudentLoginModal
			ref="resetStudentLoginModal"
			:student="user"
			@updated="$emit('reload-student')"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Avatar from '@/components/ui/user/Avatar.vue';
import ExerciseIcon from '@/components/exercises/ExerciseIcon';
import ResetStudentLoginModal from '@/components/student/ResetStudentLoginModal';
import { formatLargeNumber, round } from '@/utils/math';

export default {
	name: 'Profile',
	components: { Avatar, ExerciseIcon, ResetStudentLoginModal },
	props: {
		user: {
			type: Object,
			required: true,
		},
		overallStats: {
			type: Object,
			default: null,
		},
		withProfileLink: {
			type: Boolean,
			default: false,
		},
		withStudentResetLink: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('user', ['school']),
		statDifficultyGroups() {
			return [
				[this.getExerciseDifficultyGroup(1), this.getExerciseDifficultyGroup(2)],
				[
					this.getExerciseDifficultyGroup(3),
					this.getExerciseDifficultyGroup(4, 'Eksamen', 'exam'),
				],
			];
		},
	},
	methods: {
		showStudentReset() {
			this.$refs.resetStudentLoginModal.show();
		},
		getExerciseDifficultyGroup(difficulty, title = null, icon = null) {
			return {
				title: title ?? `Niveau ${difficulty}`,
				icon: icon ?? `difficulty${difficulty}`,
				value: this.overallStats?.difficulties?.[difficulty]?.value ?? 0,
			};
		},
		formatLargeNumber,
		round,
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.user-profile {
	padding-bottom: 0;
	overflow-y: hidden;

	.avatar {
		width: 100%;
	}

	.student-stats {
		margin-left: -2rem;
		margin-right: -2rem;

		.student-stats-item-value {
			margin-top: -0.5rem;
		}
	}

	hr {
		height: 2px;
		background-color: $grey-lighter;
		margin-left: 1rem;
		margin-right: 1rem;
	}
}
</style>
