<template>
	<div class="base-skeleton" :class="[`is-${size}`, `is-${position}`, { 'is-animated': animate }]">
		<div v-for="(_, i) in items" :key="i" class="base-skeleton-item" :class="itemClasses" :style="style" />
	</div>
</template>

<script>
export default {
	name: 'BaseSkeleton',
	props: {
		circle: {
			type: Boolean,
			default: false,
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		count: {
			type: Number,
			default: 1,
		},
		width: {
			type: [Number, String],
			default: undefined,
		},
		height: {
			type: [Number, String],
			default: undefined,
		},
		position: {
			type: String,
			default: 'left',
			validator(value) {
				return ['left', 'centered', 'right'].indexOf(value) >= 0;
			},
		},
		size: {
			type: String,
			default: 'normal',
		},
		colorName: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: '',
		},
		animate: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		items() {
			return new Array(this.count);
		},
		style() {
			const style = {};
			if (this.width !== undefined) {
				style.width = this.width instanceof Number ? `${this.width}px` : this.width;
			}
			if (this.height !== undefined) {
				style.height = this.height instanceof Number ? `${this.height}px` : this.height;
			}
			if (this.circle) {
				style['border-radius'] = '50%';
			}
			if (this.hasSpecificColor) {
				style['background-color'] = this.color;
			}

			return style;
		},
		hasColorName() {
			return !!this.colorName;
		},
		hasSpecificColor() {
			return !!this.color;
		},
		itemClasses() {
			const classNames = {
				'is-rounded': this.rounded,
				'has-specific-background': this.hasColorName || this.hasSpecificColor,
			};
			if (this.hasColorName) {
				classNames[`has-background-${this.colorName}`] = true;
			}
			return classNames;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';

$skeleton-color: $grey-lighter;
$skeleton-background: linear-gradient(90deg, $skeleton-color 25%, rgba($skeleton-color, 0.5) 50%, $skeleton-color 75%);

.base-skeleton {
	display: inline-flex;
	flex-direction: column;
	vertical-align: middle;
	width: 100%;
	+ .base-skeleton {
		margin-top: 0.5rem;
	}

	.base-skeleton-item {
		background: $skeleton-background;
		&.has-specific-background {
			background: currentColor;
		}
		background-size: 400% 100%;
		width: 100%;
		line-height: 1rem;
		transition: background 250ms ease;
		&.is-rounded {
			border-radius: $radius;
		}
		&::after {
			content: '\00a0';
		}
		+ .base-skeleton-item {
			margin-top: 0.5rem;
		}
	}

	&.is-small {
		.base-skeleton-item {
			line-height: 0.75rem;
		}
	}
	&.is-medium {
		.base-skeleton-item {
			line-height: 1.5rem;
		}
	}
	&.is-large {
		.base-skeleton-item {
			line-height: 2.5rem;
		}
	}

	&.is-animated {
		.base-skeleton-item {
			animation: base-skeleton-loading 1.5s infinite;
		}
	}
	&.is-centered {
		align-items: center;
	}
	&.is-right {
		align-items: flex-end;
	}
}

@keyframes base-skeleton-loading {
	0% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}
</style>
